@import 'styles/colours';

.RegisterBox {
  &-error-div{
    height: 18px;
  }

  &-inverted-btn{
    background-color: $secondary !important;
    &:hover{
      filter: brightness(97%)
    }
  }

  &-text-btn{
    background-color: transparent !important;
    padding: 0 !important;
    border: none !important;
    height: 22px !important;
  }
}

