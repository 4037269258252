@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

// Update Bulma's global variables
$family-primary: "Lato", sans-serif;
html, body, #root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@import "colours";
@import "mixins";

/*
* Import only what you need from Bulma
 */

/*
* Base
 */
//@import "node_modules/bulma/sass/base/_all.sass";

/*
* Components
 */
//@import "node_modules/bulma/sass/components/_all.sass";

/*
* Elements
 */
//@import "node_modules/bulma/sass/elements/_all.sass";

/*
* Form
 */
//@import "node_modules/bulma/sass/form/_all.sass";

/*
* Grid
 */
//@import "node_modules/bulma/sass/grid/_all.sass";

/*
* Helpers
 */
//@import "node_modules/bulma/sass/helpers/_all.sass";

/*
* Layout
 */
//@import "node_modules/bulma/sass/layout/_all.sass";

/*
* Utilities
 */
//@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/utilities/derived-variables";
$addColors: (
        "standout":($standout, $standout-invert),
        "secondary":($secondary, $secondary-invert),
        "error":($error, $error-invert),
        "green":($green, $green-invert),
        "yellow":($yellow, $yellow-invert),
        "red":($red, $red-invert),
        "blue":($blue, $blue-invert),
        "mature": ($mature,#fff),
        "maturing": ($maturing,#fff),
        "developing": ($developing,#fff),
        "starting": ($starting,#fff),
        "early": ($early,#fff),
        "unsure": ($unsure,#fff),
        "ds-green-dark": ($ds-green-dark, #fff),
        "ds-green":    ($ds-green, #fff),
        "ds-yellow": ($ds-yellow, #fff),
        "ds-orange": ($ds-orange, #fff),
        "ds-red": ($ds-red, #fff),
        "ds-red-dark": ($ds-red-dark, #fff),
        "developed-advanced": ($developed-advanced, #fff),
        "developing-advanced": ($developing-advanced, #fff),
        "developed-proficient": ($developed-proficient, #fff),
        "developing-proficient": ($developing-proficient, #fff),
        "developed-foundational": ($developed-foundational, #fff),
        "developing-foundational": ($developing-foundational, #fff)
);
$colors: map-merge($colors, $addColors);

/*
* This will import all of Bulma styles
 */
@import 'node_modules/bulma/bulma.sass';

/*
* Import Bulma-extensions
 */

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  font-size: $size-6 !important;
  hyphens: none !important;
}
// remove gap from bulma divider
$divider-margin-inner-size: 0px;

@import "node_modules/bulma-switch/src/sass/index";
@import "node_modules/bulma-tooltip/src/sass/index";
@import "node_modules/bulma-timeline/src/sass/index";
@import "node_modules/bulma-switch/src/sass/index";
@import "~@creativebulma/bulma-divider";
// Import style sheets

.isActiveResource {
  text-decoration: underline;
  background-color: #e88b6a !important;
  color: white !important;
}
