@import "node_modules/bulma/sass/utilities/mixins";

.LoginPage-bg{
  background: linear-gradient(rgba(34,62,74, 0.83), rgba(34,62,74, 0.83)),
  url('Assets/bg-clouds.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  & .hero-body{
    @include until(769px){
      padding-top: 0 !important;
    }
  }
}

.full-height {
  min-height: 850px !important;
  height: 100vh;
}


