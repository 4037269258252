@import "node_modules/bulma/sass/utilities/mixins";

.LogoContainer {

  &-gov-with{
    max-width: 90%;
    height: auto;
    @include until(769px){
      height: 40vh;
      width: auto;
    }
  }
}
