@import "../../node_modules/bulma/sass/utilities/functions";
// GovernWith colours
$azure: #223e4a;
$terracotta: #e88b6a;
$terracotta-invert: #fff;
$platinum: #E7E2DF;

// Extra Colours
$green : #92DD92;
$green-invert: findColorInvert($green);
$yellow : #E8E15E;
$yellow-invert: findColorInvert($yellow);
$red : #FF5C5C;
$red-invert: findColorInvert($red);
$blue: #60AEE6;
$blue-invert: findColorInvert($blue);

$danger: $red;
$warning: $yellow;
$info: $blue;
$success: $green;

//$mature: #027933;
$mature: #223e4a;
//$maturing: #54A300;
$maturing: #027933;
//$developing: #E4C411;
$developing: #86B950;
//$starting: #F18F01;
$starting: #E4C411;
//$early: #D14C23;
$early: #F18F01;
$unsure: #00A8E0;

$ds-green-dark: $mature;
$ds-green: $maturing;
$ds-yellow: $developing;
$ds-orange: $starting;
$ds-red: $early;
$ds-red-dark: #D14C23;

$developed-advanced: $mature;
$developing-advanced: $maturing;
$developed-proficient: $developing;
$developing-proficient: $starting;
$developed-foundational: $early;
$developing-foundational: #940C0C;

// Update Bulma's global variables
$family-serif: 'PT Serif', serif;
$grey-light: $platinum;
$primary: $azure;
$error: hsl(348, 100%, 61%);
$error-invert: findColorInvert($error);
$error-light: findLightColor($error);
$error-dark: findDarkColor($error);

$standout: $terracotta;
$standout-invert: $terracotta-invert;

$secondary: $platinum;
$secondary-invert: black;

$progress-bar-background-color: $secondary;
